.meail-details-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
}

/* Meal Deatils Style Start */
.meail-details-page .loader {
    margin-top: 10rem;
    z-index: 2;
    width: 150px;
    color: #d8ff3c;
}

.meail-details-page .meal-details {
    width: 100%;
    padding: 10vw 5vw 5vw;
    display: flex;
    gap: 3rem;
    flex-wrap: nowrap;
    justify-content: center;
    color: #0F2E06;
}

.meail-details-page .meal-details .img {
    width: 45%;
    height: 70vh;
    border-radius: 30px;
    overflow: hidden;
}

.meail-details-page .meal-details .img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

}

.meail-details-page .meal-details .details {
    flex: 1;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.meail-details-page .meal-details .details .title {
    font-size: 5vw;
    font-weight: 900;
    font-style: italic;
}

.meail-details-page .meal-details .details .descrption {
    font-size: 1.5vw;
    font-weight: 500;
}

.meail-details-page .meal-details .details .price {
    font-size: 2vw;
    font-weight: 700;
    border-radius: 20px;
    background-color: #0F2E06;
    color: #d8ff3c;
    padding: .5vw 1.5vw;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.meail-details-page .meal-details .details .shipping {
    display: flex;
    gap: 2rem;
    align-items: center;
    background-color: #d9ecd1;
    color: #0F2E06;
    border-radius: 20px;
    padding: 2vw;
    width: 100%;
}

.meail-details-page .meal-details .details .shipping .icon {
    font-size: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.meail-details-page .meal-details .details .shipping .text {
    font-size: 1.3vw;
    font-weight: 500;
}

.meail-details-page .meal-details .details .ingredients {
    display: flex;
    flex-direction: column;
    padding: 1rem .5rem;
    gap: .5rem;
    color: #0F2E06;
    border-top: 1px solid #0F2E0650;
    margin-top: 1rem;
    border-bottom: 1px solid #0F2E0650;
}

.meail-details-page .meal-details .details .ingredients .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.meail-details-page .meal-details .details .ingredients .head .title {
    font-size: 2vw;
    font-weight: 700;
}

.meail-details-page .meal-details .details .ingredients .head .icon {
    font-size: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.meail-details-page .meal-details .details .ingredients .ingredient-box {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5vw;

}

.meail-details-page .meal-details .details .ingredients .ingredient-box .ingredient {
    font-weight: 500;
}

.meail-details-page .meal-details .details .ingredients .ingredient-box .ingredient .amount {}


/* Meal Deatils Style Finish */

/* More Meals Style Finish */

.meail-details-page .more-meals {
    width: 100%;
    padding: 0 5vw;
}

.meail-details-page .more-meals .more-meals-title {
    font-size: 5vw;
    font-weight: 700;
    color: #0F2E06;
    margin-bottom: 2rem;
    font-style: italic;
}

.meail-details-page .more-meals .prodcuts {}

/* More Meals Style Finish */


/* Small PC style start */

@media only screen and (max-width: 1024px) {}

/* Small PC Style Finish */

/*  Tbalet style start */

@media only screen and (max-width: 768px) {
    .meail-details-page .more-meals .more-meals-title {
        font-size: 7vw;
    }
}

/*  Tbalet Style Finish */

/*  big phone style start */

@media only screen and (max-width: 500px) {
    .meail-details-page .meal-details {
        flex-direction: column;
        gap: 3rem;

    }

    .meail-details-page .meal-details .img {
        width: 100%;
        height: 50vh;
        margin-top: 5rem;
    }

    .meail-details-page .meal-details .details .title {
        font-size: 10vw;
    }

    .meail-details-page .meal-details .details .descrption {
        font-size: 4vw;

    }

    .meail-details-page .meal-details .details .price {
        padding: .5rem 1rem;
        font-size: 5vw;
    }

    .meail-details-page .meal-details .details .shipping {
        padding: 1rem;
    }

    .meail-details-page .meal-details .details .shipping .icon {
        font-size: 10vw;
    }

    .meail-details-page .meal-details .details .shipping .text {
        font-size: 3vw;
    }

    .meail-details-page .meal-details .details .ingredients .head .title {
        font-size: 7vw;
    }

    .meail-details-page .meal-details .details .ingredients .ingredient-box {
        font-size: 4vw;
    }

    .meail-details-page .meal-details .details .ingredients .head .icon {
        font-size: 8vw;
    }

    .meail-details-page .more-meals .more-meals-title {
        font-size: 12vw;
    }
}

/*  big phone Style Finish */

/*  phone style start */

@media only screen and (max-width: 400px) {}

/*  phone Style Finish */